<template>
  <div class="page-seacher" :style="paddingBottom">
    <el-form :inline="true" :model="searchModel" @submit.native.prevent class="page-searcher-form" label-width="80px"
             ref="pageSearchRef" size="mini" style="flex: 1;">
      <div :class="showMoreSearch ? 'show-more' : 'hide-more'"
           class="page-searcher-items" ref="searchItemsRef">
        <slot name="search-items"></slot>
        <el-form-item class="search-actions">
        </el-form-item>
      </div>
    </el-form>
    <div class="custom-search-actions">
      <el-button @click="switchSearchStatus" type="text" v-if="searchItemCount > 5" >
        <div style="display: flex;align-items: center;height: 100%;">
          <span style="color: #0D6CE4;">{{ showMoreSearch ? '收起' : '展开' }}</span>
          <img class="hide-more-iicon" :class="showMoreSearch ? 'hide-more-icon' : 'show-more-icon'" src="@/assets/img/down.png" alt="">
        </div>
      </el-button>
    </div>
    <div class="find-handler-actions">
      <el-button size="small" @click="searchFormReset('pageSearchRef')" plain>重置</el-button>
      <el-button size="small" @click="searchFormSubmit" type="primary">查找</el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "PageSearcher",
  props: {
    searchModel: {
      type: Object,
      default() {
        return {}
      }
    },
    searchMethod: {
      type: Function,
      required: true
    }
  },
  computed: {
    paddingBottom(){
      if (this.searchItemCount < 5) {
        return {
          paddingBottom: '12px'
        }
      }
      return {}
    }
  },
  data() {
    return {
      searchItemCount: 0,
      showMoreSearch: false,
    }
  },
  created() {

  },
  mounted() {
    let that = this
    that.initSearchItems()
  },
  methods: {
    initSearchItems() {
      let that = this
      that.searchItemCount = that.$refs.searchItemsRef.children.length - 1
      let searchItems = that.$refs.searchItemsRef.children
      let last_span = 0
      for (let i = 0; i < searchItems.length; i++) {
        if (searchItems[i].classList.contains('search-actions')) continue
        let itemSpan = 1
        if (searchItems[i].hasAttribute('span')) {
          itemSpan = parseInt(searchItems[i].getAttribute('span'))
        }
        last_span = last_span + itemSpan
        if (last_span > 5) {
          searchItems[i].setAttribute('more-action', 'true')
        } else {
          searchItems[i].removeAttribute('more-action')
        }
      }
    },
    searchFormSubmit() {
      if (this.searchMethod) {
        this.searchMethod()
      }
    },
    searchFormReset(formName) {
      this.$refs[formName].resetFields()
      this.searchMethod()
    },
    switchSearchStatus() {
      this.showMoreSearch = !this.showMoreSearch
    }
  },
}
</script>

<style lang="scss" scoped>
.page-seacher {
  display: flex;
  padding: 18px 0 0;
  margin-bottom: 18px;
  background-color: #FFFFFF;

  border-bottom: 1px solid #EEF1F5;

  /deep/ .el-form-item__label{
    color: #2F3133;
  }

  .custom-search-actions{
    width: 120px;
    height: 49px;
    display: flex;
    align-items: center;
    justify-content: center;

    .el-icon-d-arrow-left{
        transition: all .3s ease 0s;
    }

    .hide-more-iicon{
      margin-left: 10px;
      width: 16px;
      height: 16px;
    }

    .show-more-icon {
      transform: rotate(1turn);
    }

    .hide-more-icon {
      transform: rotate(0.5turn);
    }
  }

  .find-handler-actions{
    height: 49px;
    display: flex;
    align-items: center;
  }

  .page-searcher-form {
    min-height: 36px;

    .page-searcher-items {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-start;

      & /deep/ .el-form-item {
        transition: opacity .2s linear;
        display: block;
        padding-left: 12px;
        padding-right: 12px;
        margin: 10px 0;

        @media only screen and (max-width: 1920px) {
          & {
            width: 20%;
            flex: 0 0 20%;
          }

          &[span="2"] {
            flex: 0 0 40%;
          }
        }

        @media only screen and (max-width: 1200px) {
          & {
            width: 25%;
            flex: 0 0 25%;
          }

          &[span="2"] {
            flex: 0 0 50%;
          }
        }

        @media only screen and (max-width: 992px) {
          & {
            width: 50%;
            flex: 0 0 50%;
          }

          &[span="2"] {
            flex: 0 0 100%;
          }
        }

        @media only screen and (max-width: 767px) {
          & {
            width: 100%;
            flex: 0 0 100%;
          }

          &[span="2"] {
            flex: 0 0 100%;
          }
        }

        .el-form-item__label {
          white-space: nowrap;
        }

        .el-form-item__content {
          width: calc(100% - 80px);

          .el-select,
          .el-cascader,
          .el-date-editor,
          .el-range-editor,
          .el-input__inner {
            width: 100%;
          }
        }
      }

      &.show-more {
        & /deep/ .el-form-item {
          display: inline-block;;
        }
      }

      &.hide-more {
        & /deep/ .el-form-item[more-action] {
          display: none;
        }
      }

      .search-actions {
        flex: 1;
        display: flex !important;
        justify-content: flex-end;
        max-width: unset;

        & /deep/ .el-form-item__content {
          min-width: 260px;
          display: flex;
          justify-content: flex-end;

          .el-icon-arrow-down {
            margin-left: .5em;
            transition: all .3s ease 0s;
          }

          .show-more-icon {
            transform: rotate(0turn);
          }

          .hide-more-icon {
            transform: rotate(.5turn);
          }
        }

        & /deep/ .el-button {
          align-self: flex-start;
        }
      }
    }
  }
}
</style>
